<template>
	<div>
		<!-- top image bar -->
		<Header :propsObj="page"></Header>

		<!-- at home - logo button - at school -->
		<!-- <HomeSchoolBar></HomeSchoolBar> -->

		<!-- introduction -->
		<Intro :title="page.intro_title" :content="page.intro_content"></Intro>

		<!-- content -->
		<div class="container mt-5">
			<div class="row">
				<div class="col-12">
					<button
						v-if="cart.length > 0"
						type="button"
						class="btn btn-success float-right m-4"
						@click="showCart"
					>
						<p>Naar winkelmand</p>
						<v-icon title="wagentje" name="shopping-cart" scale="2" />
						&nbsp;({{ cart.length }})
					</button>
				</div>

				<div class="col-12">
					<table class="table table-hover">
						<!-- <thead>
							<tr>
								<th>Omschrijving</th>
								<th class="w-auto">Prijs</th>
								<th class="w-auto"></th>
							</tr>
						</thead> -->
						<tbody>
							<tr v-for="(p, index) in prices" :key="p.id">
								<td class="w-60 linewrap" :class="{ 'font-weight-bold': p.remarks == '*' }">
									{{ p.description }}
								</td>
								<td class="linewrap">{{ p.price }}</td>
								<td :class="{ 'text-white': p.remarks == '*' }">{{ p.remarks }}</td>
								<td>
									<button
										v-if="p.price.indexOf('€') >= 0"
										type="button"
										class="btn btn-success btn-sm"
										@click="addToCart(index)"
									>
										in winkelwagen
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<!-- footer  -->
		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios';

import Header from '@/components/Header.vue';
import HomeSchoolBar from '@/components/HomeSchoolBar.vue';
import Intro from '@/components/Intro.vue';
import Footer from '@/components/Footer.vue';

import 'vue-awesome/icons/shopping-cart';
import Icon from 'vue-awesome/components/Icon';

export default {
	data() {
		return {
			prices: [],
			BASE_URL: '/php/cms.php',
			TABLE_PRICES: '/prices/',

			cart: [],
		};
	},

	props: {
		route: String,
	},

	components: {
		Header,
		HomeSchoolBar,
		Intro,
		Footer,
		'v-icon': Icon,
	},

	computed: {
		// quarks() {
		// 	let qq = this.$store.getters.quarks;
		// 	// console.log('main.vue computed quarks', quarks.length);
		// 	if (qq.length > 0) {
		// 		this.handleQuarks(qq);
		// 		// console.log(qq);
		// 		return qq;
		// 	}
		// },
		page() {
			let pp = this.$store.getters.page(this.route);
			if (pp.header_image == 'afbeelding') {
				pp.header_image = '../uploads/blokken.jpg';
			}
			return pp;
		},
	},

	created: function () {
		console.clear();
		var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -5).replace('T', ' ');
		//console.log('prices', localISOTime);

		// console.log(data.Blad1);
		// this.prices = data.Blad1;
		this.getPrices();

		// read from local storage
		if (sessionStorage.getItem('cart')) {
			this.cart = JSON.parse(sessionStorage.getItem('cart'));
		}
	},

	methods: {
		async getPrices() {
			let url = this.BASE_URL + this.TABLE_PRICES;

			await axios
				.get(url)
				.then((response) => {
					//console.log('response.data', response.data.length);
					if (!response.data.error) {
						for (let i = 0; i < response.data.length; i++) {
							// console.log('p', response.data[i]);
							if (response.data[i].visible != '-') {
								this.prices.push(response.data[i]);
							}
						}
						// console.log('prices', this.prices);
					} else {
						console.log('Error', response.data.error.code, ':', response.data.error.status);
					}
				})
				.catch((e) => {
					// this.errors.push(e)
					//console.log(e);
				});
		},

		addToCart(index) {
			const element = this.prices[index];
			let present = false;
			for (let i = 0; i < this.cart.length; i++) {
				if (element.id === this.cart[i].id) {
					present = true;
				}
			}

			if (!present) {
				element.quantity = 1;
				this.cart.push(element);
			}

			console.log(this.cart);
		},

		showCart() {
			// save to local storage
			sessionStorage.setItem('cart', JSON.stringify(this.cart));
			this.$router.push('shoppingcart');
		},
	},
};
</script>

<style scoped>
.linewrap {
	white-space: pre-line;
}

.w-60 {
	width: 60%;
}

a {
	color: #be1622;
}

a:hover {
	color: #f39200;
	text-decoration: none;
}
</style>
